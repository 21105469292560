interface InvQty {
  number: String;
  characters: String;
}

export const separateInvQty = (invQty: String): InvQty => {
  // Use regex to match the number with commas and the characters

  const matches = invQty.match(/^([\d,]+(?:\.\d+)?)([a-zA-Z]+)\.?$/);

  if (matches) {
    return {
      number: matches[1],
      characters: matches[2],
    };
  }
  return {
    number: "",
    characters: "",
  };
};
