import axios from "axios";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import HashLoader from "react-spinners/HashLoader";
import { pdfjs, Document } from "react-pdf";
import { MdOutlineUploadFile } from "react-icons/md";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { IProcessResultProps } from "../../interface/processResult";
import {
  KeyProcessDetailEnum,
  KeyProcessHeaderEnum,
  ProcessTypeEnum,
} from "../../enum/processType";
import * as XLSX from "xlsx";
import { IDataDetailMap, IDataHeaderMap } from "../../interface/mapData";
import { convertDateFormat } from "../../util/convertDateFormat";
import { getCountryInitials } from "../../util/getNameCountry";
import { separateInvQty } from "../../util/separateInvQty";
import { RenderPreviewFile } from "../../components/pdfViewer";
import PDFDocument from "pdf-lib/cjs/api/PDFDocument";
import "./uploadPage.scss";
import uploadIcon from "../../assets/upload-icon.svg";
import CardComponent from "../../components/cardComponent/cardComponent";
import { useFileContext } from "../../context/fileContext";
import { useNavigate } from "react-router-dom";
import { DisplayFileModeEnum } from "../../enum/displayFileMode";
import { IKeywordList } from "../previewFilePage/previewFilePage";
import { useErrorContext } from "../../context/errorModalContext/errorModalContext";

pdfjs.GlobalWorkerOptions.workerSrc =
  await `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const fileTypes = ["PDF"];

const UploadFile: React.FC = () => {
  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const navigate = useNavigate();

  const {
    setContextFile,
    fileContext,
    displayMode,
    setDisplayFileMode,
    setSepInvoiceFile,
    setSepPackListFile,
  } = useFileContext();

  const [file, setFile] = useState<File | null>(null);
  const [sepInvoice, setSepInvoice] = useState<File | null>(null);
  const [sepPacking, setSepPacking] = useState<File | null>(null);

  const [processType, setProcessType] = useState<ProcessTypeEnum>(
    ProcessTypeEnum.INVOICE
  );

  const [opacity, setOpacity] = useState<number>(1);

  const [hideAllCase, setHideAllCase] = useState<boolean>(false);

  const { errorModalComponent, hideErrorModal, showErrorModal } =
    useErrorContext();

  const uploadAllCaseEvent = (file: File) => {
    setContextFile(file);
    setSepPackListFile(undefined);
    setSepInvoiceFile(undefined);

    navigate("/preview-file");
  };

  const uploadSepCaseEvent = async (
    file: File,
    processType: ProcessTypeEnum,
    getKeyword: IKeywordList[]
  ) => {
    setOpacity(0);
    setContextFile(undefined);

    setDisplayFileMode(DisplayFileModeEnum.INVOICE_PACKING_LIST);

    if (processType === ProcessTypeEnum.INVOICE) {
      const formData = new FormData();
      formData.append("file", file);

      const getInvoice = getKeyword.find((e) =>
        e.keyword.toLowerCase().includes("invoice".toLowerCase())
      );

      const getPackingList = getKeyword.find((e) =>
        e.keyword.toLowerCase().includes("packing list".toLowerCase())
      );

      if (!getInvoice && getPackingList) {
        showErrorModal(
          "Invoice Is Require!",
          <div>
            System detect your file upload has only
            <span className="font-bold-700 text-danger ms-1">Packing List</span>
            .
          </div>,
          "alert"
        );
      } else {
        setSepInvoice(file);
        setSepInvoiceFile(file);
      }
    } else if (processType === ProcessTypeEnum.PACKING_LIST) {
      const formData = new FormData();
      formData.append("file", file);
      const getInvoice = getKeyword.find((e) =>
        e.keyword.toLowerCase().includes("invoice".toLowerCase())
      );

      const getPackingList = getKeyword.find((e) =>
        e.keyword.toLowerCase().includes("packing list".toLowerCase())
      );

      if (getInvoice && !getPackingList) {
        showErrorModal(
          "Packing List Is Require!",
          <div>
            System detect your file upload has only
            <span className="font-bold-700 text-danger ms-1">Invoice</span>.
          </div>,
          "alert"
        );
      } else {
        setSepPacking(file);
        setSepPackListFile(file);
      }
    }
  };

  useEffect(() => {
    if (sepInvoice && sepPacking) {
      setDisplayFileMode(DisplayFileModeEnum.INVOICE_PACKING_LIST);
      navigate("/preview-file");
    }
  }, [sepInvoice, sepPacking]);

  const handleCheckDoc = async (file: File, processType: ProcessTypeEnum) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      if (event.target?.result) {
        const typedarray = new Uint8Array(event.target.result as ArrayBuffer);
        const pdf = await pdfjs.getDocument(typedarray).promise;

        const occurrences: IKeywordList[] = [];

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          textContent.items.forEach((item) => {
            if ("str" in item) {
              if (
                ["invoice", "packing list"].some(
                  (keyword) =>
                    item.str.toLowerCase().includes(keyword.toLowerCase()) &&
                    item.str.length === keyword.length
                )
              ) {
                if (
                  !occurrences.find(
                    (e) => e.keyword.toLowerCase() === item.str.toLowerCase()
                  )
                ) {
                  occurrences.push({
                    keyword: item.str,
                    startPage: i,
                  });
                }
              }

              return item.str;
            }
          });
        }
        uploadSepCaseEvent(file, processType, occurrences);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <Container
        className="upload-page col-9 mt-4 py-4"
        style={{
          overflowY: opacity === 0 ? "hidden" : "auto",
          height: opacity === 0 ? "95vh" : "",
        }}
      >
        <div
          style={{
            opacity: opacity,
            transform:
              opacity === 0 ? "translate(0px, -100rem)" : "translate(0px, 0px)",
            transition: "all 0.5s ease-in-out",
          }}
          hidden={hideAllCase}
        >
          <CardComponent>
            <div className="main-upload">
              <div className="header-card text-start">
                <div className="title-card-area">Document AI</div>
                <div className="sub-title">
                  Add your documents here, and you can upload only 1 file
                </div>
              </div>
              <div className="mt-3">
                <FileUploader
                  handleChange={uploadAllCaseEvent}
                  name="file"
                  types={fileTypes}
                >
                  <div className="custom-input-upload">
                    <Col>
                      <img src={uploadIcon} />
                      <h5 className="mt-3">
                        Drag your file to start uploading
                        <div>OR</div>
                      </h5>
                      <div className="fake-button-browse-file mx-auto font-bold-600 px-3 py-1">
                        Browse files
                      </div>
                    </Col>
                  </div>
                </FileUploader>
              </div>
              <div className="text-start mt-2 text-danger">
                File type support Only : PDF
              </div>
            </div>
          </CardComponent>
        </div>
        <div className="my-5 text-center font-bold-700" hidden={opacity === 0}>
          or you can upload files separately ...
        </div>
        <div
          className="mb-5"
          style={{
            transform:
              opacity === 0 ? "translate(0px, -80%)" : "translate(0px, 0px)",
            transition: "all 0.5s ease-in-out",
            height: "fit-content",
          }}
        >
          <Row className="separate-file">
            <CardComponent classNames="col-5">
              <div className="header-card text-start">
                <div className="title-card-area">Invoice</div>
                <div className="sub-title">
                  Add your documents here, and you can upload only 1 file
                </div>
              </div>
              <div className="mt-3">
                <FileUploader
                  handleChange={(file: any) =>
                    handleCheckDoc(file, ProcessTypeEnum.INVOICE)
                  }
                  name="file"
                  types={fileTypes}
                >
                  {sepInvoice === null ? (
                    <div
                      className={`custom-input-upload ${
                        !sepInvoice && sepPacking ? "require" : ""
                      }`}
                    >
                      <Col>
                        <img src={uploadIcon} />
                        <h5 className="mt-3">
                          {!sepInvoice && sepPacking ? (
                            <div className="text-danger">Invoice Require!</div>
                          ) : (
                            <div>Drag your file to start uploading</div>
                          )}
                          <div
                            className={`mt-2 text-danger ${
                              !sepInvoice && sepPacking ? "type-alert" : ""
                            }`}
                          >
                            File type support Only : PDF
                          </div>
                          {!sepInvoice && sepPacking ? <></> : <div>OR</div>}
                        </h5>
                        <div
                          className={`fake-button-browse-file mx-auto font-bold-600 px-3 py-1 ${
                            !sepInvoice && sepPacking ? "require mt-4" : ""
                          }`}
                        >
                          Browse files
                        </div>
                      </Col>
                    </div>
                  ) : (
                    <div className="custom-input-upload d-flex justify-content-center">
                      <div>
                        <img src={uploadIcon} />
                        <div className="text-primary font-bold-600 mt-3">
                          File Name : {sepInvoice.name}
                        </div>
                      </div>
                    </div>
                  )}
                </FileUploader>
              </div>
            </CardComponent>
            <CardComponent classNames="col-5">
              <div className="header-card text-start">
                <div className="title-card-area">Packing list</div>
                <div className="sub-title">
                  Add your documents here, and you can upload only 1 file
                </div>
              </div>
              <div className="mt-3">
                <FileUploader
                  handleChange={(file: any) =>
                    handleCheckDoc(file, ProcessTypeEnum.PACKING_LIST)
                  }
                  name="file"
                  types={fileTypes}
                >
                  {sepPacking === null ? (
                    <div
                      className={`custom-input-upload ${
                        sepInvoice && !sepPacking ? "require" : ""
                      }`}
                    >
                      <Col>
                        <img src={uploadIcon} />
                        <h5 className="mt-3">
                          {sepInvoice && !sepPacking ? (
                            <div className="text-danger">
                              Packing List Require!
                            </div>
                          ) : (
                            <div>Drag your file to start uploading</div>
                          )}
                          <div
                            className={`mt-2 text-danger ${
                              sepInvoice && !sepPacking ? "type-alert" : ""
                            }`}
                          >
                            File type support Only : PDF
                          </div>
                          {sepInvoice && !sepPacking ? <></> : <div>OR</div>}
                        </h5>
                        <div
                          className={`fake-button-browse-file mx-auto font-bold-600 px-3 py-1 ${
                            sepInvoice && !sepPacking ? "require mt-4" : ""
                          }`}
                        >
                          Browse files
                        </div>
                      </Col>
                    </div>
                  ) : (
                    <div className="custom-input-upload d-flex justify-content-center">
                      <div>
                        <img src={uploadIcon} />
                        <div className="text-primary font-bold-600 mt-3">
                          File Name : {sepPacking.name}
                        </div>
                      </div>
                    </div>
                  )}
                </FileUploader>
              </div>
            </CardComponent>
          </Row>
          <div className="text-center mt-4">
            <Button
              hidden={opacity !== 0}
              variant="secondary"
              onClick={() => {
                setFile(null);
                setSepInvoice(null);
                setSepPacking(null);
                setOpacity(1);
              }}
            >
              Clear File
            </Button>
          </div>
        </div>

        {errorModalComponent}
      </Container>
    </>
  );
};

export default UploadFile;
