import "./descriptionRateScore.scss";

const DescriptionRateScore: React.FC = () => {
  return (
    <div className="description-zone">
      <div className="gap-2 d-flex align-items-center">
        <div className="d-flex gap-2 align-items-center">
          <div className="good-rate color-rate" />
          <div className="note">confidence 80-100%</div>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <div className="improve-rate color-rate" />
          <div className="note">confidence 50-80%</div>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <div className="bad-rate color-rate" />
          <div className="note">confidence 00-50%</div>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <div className="edited color-rate" />
          <div className="note">Edited</div>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <div className="error color-rate" />
          <div className="note">Error</div>
        </div>
      </div>
    </div>
  );
};

export default DescriptionRateScore;
