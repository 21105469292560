import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "./pages/login/loginPage";
import "./style/app.scss";
import UploadFile from "./pages/upload/uploadFile";
import { FileProvider } from "./context/fileContext";
import PreviewFilePage from "./pages/previewFilePage/previewFilePage";
import PreviewAiResult from "./pages/previewAiResult/previewAiResult";
import { ErrorModalProvider } from "./context/errorModalContext/errorModalContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: <UploadFile />,
  },
  {
    path: "/preview-file",
    element: <PreviewFilePage />,
  },
  {
    path: "/preview-ai-result",
    element: <PreviewAiResult />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ErrorModalProvider>
    <FileProvider>
      <RouterProvider router={router} />
    </FileProvider>
  </ErrorModalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
