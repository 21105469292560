import dayjs from "dayjs";

export const convertDateFormat = (date: String): String => {
  const dateString = date;
  const dateParts = dateString.split("/");
  const month = parseInt(dateParts[0], 10) - 1;
  const day = parseInt(dateParts[1], 10);
  const year = parseInt(dateParts[2], 10);
  const newDate = new Date(year, month, day);

  return dayjs(newDate).format("DD/MM/YYYY");
};
