import { ReactNode, RefObject } from "react";
import "../cardComponent/cardComponent.scss";
import { Container } from "react-bootstrap";

interface ICardComponent {
  children: ReactNode;
  classNames?: string;
  ref?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined;
}

const CardComponent: React.FC<ICardComponent> = ({
  children,
  classNames = "",
  ref = undefined,
}) => {
  return (
    <Container className={`card-component ${classNames}`} ref={ref}>
      {children}
    </Container>
  );
};

export default CardComponent;
